import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, Grid, Snackbar, IconButton, Select, Paper, MenuItem, TextField, InputLabel, FormControl } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import axios from 'axios';
import CheckoutConfirmationDialog from './CheckoutConfirmationDialog';

const apiUrl = process.env.REACT_APP_API_URL;

const Cart = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [ppn, setPpn] = useState(0); // Store PPN percentage
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [cities, setCities] = useState([]); // State untuk menyimpan data kota/kabupaten
  const [selectedCity, setSelectedCity] = useState(''); // State untuk kota/kabupaten terpilih
  const [ekspedisis, setEkspedisis] = useState([]);
  const [selectedEkspedisi, setSelectedEkspedisi] = useState('');
  const [ongkir, setOngkir] = useState(0); // State untuk ongkir
  const [ongkirOptions, setOngkirOptions] = useState([]);
  const [selectedOngkir, setSelectedOngkir] = useState(null);
  const [alamatLengkap, setAlamatLengkap] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
    const fetchPpn = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ppn`);
        if (response.data && response.data.length > 0) {
          setPpn(parseFloat(response.data[0].bobot_ppn));
          console.log(`ini ppn : ${parseFloat(response.data[0].bobot_ppn)}`);
        }
      } catch (error) {
        console.error('Failed to fetch PPN:', error);
      }
    };

    // Fetch provinces data from local JSON
    const fetchProvinces = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/provinsi`);
        const data = await response.json();
        setProvinces(data.data);
      } catch (error) {
        alert('Failed to fetch provinces, please try again later.');
      }
    };

    const fetchEkspedisi = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/ekspedisi`);
        // console.log('Response data:', response.data);
        setEkspedisis(response.data.data || []);
      } catch (error) {
        console.error('Failed to fetch ekspedisi:', error);
      }
    };

    fetchEkspedisi();

    fetchPpn();
    fetchProvinces();
  }, []);

  const handleRemoveFromCart = (id) => {
    const updatedCart = cartItems.filter(item => item.id_sli !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    window.location.reload();
  };

  const handleQuantityChange = (id, delta) => {
    const updatedCart = cartItems.map(item => {
      if (item.id_sli === id) {
        const newQuantity = item.quantity + delta;
        return { ...item, quantity: Math.max(1, newQuantity) };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    window.location.reload();
  };

  const calculateTotalPrice = () => {
    const subtotal = cartItems.reduce((total, item) => total + item.unit_price * item.quantity, 0);
    const taxAmount = (ppn / 100) * subtotal; // Calculate tax amount
    return subtotal + ongkir;
  };
  const totalWeightLocal = () => {
    const dataWeight = localStorage.getItem('cart');
    if (dataWeight) {
      const parsedData = JSON.parse(dataWeight);
      const weights = parsedData.map((item) => parseInt(item.weight));
      return weights[0];
    } else {
      console.log('Cart is empty or not found in localStorage.');
      // return 0; // Kembalikan 0 jika tidak ada data
    }
  };



  const user_cek = JSON.parse(localStorage.getItem('user')) || [];
  const user_cek_id = user_cek.id_user;
  const handleCheckout = async () => {
    if (!selectedProvince || !selectedCity || !selectedEkspedisi || !selectedOngkir) {
      // alert('Mohon lengkapi data pengiriman sebelum checkout.');
      setSnackbarOpen(true);
      return;
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      const user_id = user.id_user;
      const fullname = user.fullname;
      const email = user.email;
      const phone = user.phone_number;

      const items = cartItems.map(item => ({
        id_product: item.id_sli,
        quantity: item.quantity,
        unit_price: item.unit_price,
        type_product: item.type_product,
        weight: item.weight,
      }));

      const total_price = calculateTotalPrice(); // Total harga termasuk PPN

      try {
        const response = await axios.post(`${apiUrl}api/checkout`, {
          id_user: user_id,
          items: items,
          fullname: fullname,
          email: email,
          phone_number: phone,
          total_price: calculateTotalPrice(),
          ppn_sales: ppn,
          kode_delivery: '',
          ongkir_sales: setOngkirOptions(),
          alamat: alamatLengkap,
          jasa_ekspedisi: selectedEkspedisi,
          ongkir: ongkir,
        });

        if (response.data.snap_token) {
          window.snap.pay(response.data.snap_token, {
            onSuccess: async function (result) {
              alert('Payment success! ' + JSON.stringify(result));
              const order_id = result.order_id;

              try {
                const paymentResponse = await axios.get(`${apiUrl}api/payment`, {
                  params: {
                    order_id: order_id,
                    status_code: 200,
                    transaction_status: 'settlement'
                  }
                });
                alert('Payment processed successfully!');
                navigate('/salesdetail');
              } catch (paymentError) {
                console.error('Error while hitting payment callback:', paymentError);
                alert('Error while processing payment callback.');
                localStorage.removeItem('cart');
              }
            },
            onPending: function (result) {
              alert('Waiting for your payment! ' + JSON.stringify(result));
              localStorage.removeItem('cart');
            },
            onError: function (result) {
              alert('Payment failed! ' + JSON.stringify(result));
              localStorage.removeItem('cart');
            },
            onClose: function () {
              alert('Payment dialog closed.');
              localStorage.removeItem('cart');
            },
          });
        } else {
          alert('Failed to create payment token');
        }
      } catch (error) {
        console.error(error);
        alert('Error processing checkout');
        localStorage.removeItem('cart');
      }
    }
  };


  const handleConfirmCheckout = () => {
    setOpenDialog(false);
    handleCheckout();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const openCheckoutDialog = () => {
    setOpenDialog(true);
  };

  const handleProvinceChange = async (event) => {
    const provinceId = event.target.value;

    setSelectedProvince(provinceId);
    setSelectedCity('');

    try {
      const response = await fetch(`${apiUrl}api/provinsi/city/${provinceId}`);
      const result = await response.json();
      setCities(Array.isArray(result.data) ? result.data : []);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCities([]);
    }
  };


  const handleEkspedisiChange = (event) => {
    setSelectedEkspedisi(event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleAlamatChange = (event) => {
    setAlamatLengkap(event.target.value);
  };

  // Fungsi untuk menangani request ongkir
  const handleOngkir = async () => {
    try {

      const response = await fetch(`${apiUrl}ongkir/cost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          origin: 431, // kode daerah sukabumi , cek pada raja ongkir
          destination: selectedCity,
          weight: totalWeightLocal(),
          courier: selectedEkspedisi,
        }),
      });

      const data = await response.json();
      console.log('Ongkir Data:', data); // Tambahkan log
      if (data && data.rajaongkir && data.rajaongkir.results) {
        setOngkirOptions(data.rajaongkir?.results[0]?.costs || []);
      }
    } catch (error) {
      console.error('Failed to fetch ongkir:', error);
    }
  };

  // Render pilihan ongkir
  const handleSelectOngkir = (ongkirOption) => {
    setSelectedOngkir(ongkirOption);
    console.log(ongkirOption);
    setOngkir(ongkirOption.cost[0].value * 2.5); // Simpan nilai ongkir terpilih
  };
  const handleSubmit = () => {
    console.log(`ini alamat ya ${alamatLengkap}`); // Gunakan 'alamatLengkap' untuk mencetak nilainya
  };
  const vertical = 'top';
  const horizontal = 'center';

  return (
    <Container sx={{ mt: 8, paddingBottom: 6 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold', color: '#333' }}>
        Keranjang Belanja
      </Typography>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        message="Mohon lengkapi data pengiriman sebelum checkout."

      />
      {/* Form Pilihan Lokasi */}
      <Box sx={{ mb: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="province-label">Pilih Provinsi</InputLabel>
              <Select
                labelId="province-label"
                value={selectedProvince}
                label="Pilih Provinsi"
                onChange={handleProvinceChange}
              >
                {provinces.map(province => (
                  <MenuItem key={province.province_id} value={province.province_id}>
                    {province.province_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth disabled={!selectedProvince}>
              <InputLabel id="city-label">Pilih Kota/Kabupaten</InputLabel>
              <Select
                labelId="city-label"
                value={selectedCity}
                label="Pilih Kota/Kabupaten"
                onChange={handleCityChange}
              >
                {Array.isArray(cities) && cities.map(city => (
                  <MenuItem key={city.city_id} value={city.city_id}>
                    {city.type} {city.city_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Form untuk Jasa Pengiriman */}
        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="ekspedisi-label">Pilih Jasa Pengiriman</InputLabel>
            <Select
              labelId="ekspedisi-label"
              value={selectedEkspedisi}
              label="Pilih Jasa Pengiriman"
              onChange={handleEkspedisiChange}
            >
              {Array.isArray(ekspedisis) && ekspedisis.length > 0 ? (
                ekspedisis.map((ekspedisi) => (
                  <MenuItem key={ekspedisi.id} value={ekspedisi.name}>
                    {ekspedisi.name.toUpperCase()}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Loading Ekspedisi...</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Alamat Lengkap"
            variant="outlined"
            fullWidth
            margin="normal"
            value={alamatLengkap} // Mengikat nilai input dengan state
            onChange={handleAlamatChange} // Memperbarui state saat input berubah
          />

          <Button variant="contained" color="success" onClick={handleOngkir} sx={{ mt: 2 }}>
            Cek Ongkir
          </Button>
        </Box>
      </Box>

      {/* List Ongkir */}
      <Box>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Pilih Ongkir:
        </Typography>
        {Array.isArray(ongkirOptions) && ongkirOptions.length > 0 ? (
          ongkirOptions.map((option, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{
                mb: 2,
                p: 2,
                borderRadius: 2,
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.03)",
                  backgroundColor: "#f7f7f7",
                },
                cursor: "pointer",
              }}
              onClick={() => handleSelectOngkir(option)}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#00796b' }}>
                {option.service} ( {option.description} )
              </Typography>
              <Typography variant="body1">
                {formatPrice(option.cost[0].value * 2.5)}
              </Typography>
              <Typography variant="body2">
                Estimasi: {option.cost[0].etd} (Days)
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography variant="body2" sx={{ color: "#888" }}>
            Tidak ada opsi ongkir tersedia.
          </Typography>
        )}
      </Box>

      {/* Keranjang Barang */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
          Produk dalam Keranjang:
        </Typography>
        {cartItems.length > 0 ? (
          cartItems.map(item => (
            <Box key={item.id_sli} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/brand_photos/${item.photo_brand[0]?.photo_url}`}
                alt={item.brand}
                style={{ width: 80, height: 80, borderRadius: 4, marginRight: 16 }}
              />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{item.brand} ( {item.type_product} )</Typography>
                <Typography variant="body1">{formatPrice(item.unit_price * item.quantity)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <IconButton onClick={() => handleQuantityChange(item.id_sli, -1)}>
                  <Remove />
                </IconButton>
                <Typography variant="body1" sx={{ mx: 1 }}>{item.quantity}</Typography>
                <IconButton onClick={() => handleQuantityChange(item.id_sli, 1)}>
                  <Add />
                </IconButton> */}
                <Button variant="contained" color="warning" onClick={() => handleRemoveFromCart(item.id_sli)} sx={{ ml: 2 }}>
                  Hapus
                </Button>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <ShoppingCartIcon sx={{ fontSize: 60, color: '#ccc' }} />
            <Typography variant="h6" sx={{ mt: 2 }}>Keranjang Anda Kosong</Typography>
          </Box>
        )}
        {selectedOngkir && (
          <Paper
            elevation={3}
            sx={{
              mb: 2,
              p: 2,
              borderRadius: 2,
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.03)",
                backgroundColor: "#f7f7f7",
              },
              cursor: "pointer",
            }}

          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#00796b' }}>
              {selectedOngkir.description}
            </Typography>
            <Button variant='outlined' color='primary'>{selectedOngkir.service} - {formatPrice(selectedOngkir.cost[0].value * 2.5)}</Button>
          </Paper>
        )}
      </Box>

      {/* Total dan Checkout */}
      {cartItems.length > 0 && (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {/* Total Harga (PPN {ppn}%): {formatPrice(calculateTotalPrice())} */}
            Total Harga (Sudah Termasuk Jasa Kirim): {formatPrice(calculateTotalPrice())}
          </Typography>
          <Button
            sx={{ display: 'flex' }}
            variant="contained"
            color="primary"
            onClick={openCheckoutDialog}
          >
            Checkout
          </Button>

          <CheckoutConfirmationDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmCheckout}
          />
        </Box>
      )}
    </Container>
  );
};

const formatPrice = (price) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(price);
};

export default Cart;
